import React, { useEffect, useRef, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import useApi from 'src/Api/useApi';
import SpaceContainer from './spaceContainer';
import Space from './space';
import PendingRows from 'src/components/ui/pendingRows';

export default function Home() {
  const [spaces, setSpaces] = useState([]);
  const { api, inProgress } = useApi();
  const cancel = useRef(null)

  useEffect(() => {
    const local = async () => {
      const data = await api('analysis/spaces', null, {setCancelCallback: (callback) => cancel.current = callback});

      data && data.length && setSpaces(data);
    };

    local();

    return () => cancel?.current && cancel.current.abort()
  }, []);

  return (
    <Paper>
      <Typography variant="h4" style={{ textAlign: 'center', padding: '20px' }}>
        Программы
      </Typography>
      <SpaceContainer>
        <PendingRows inProgress={inProgress} />
        {!inProgress && spaces.map((i) => <Space {...i} />)}
        {!inProgress && !spaces.length && (
          <Typography
            variant="h5"
            style={{ textAlign: 'center', padding: '20px' }}
          >
            Нет данных
          </Typography>
        )}
      </SpaceContainer>
    </Paper>
  );
}
