import Header from 'src/components/Header';
// import MainMenu from 'src/components/MainMenu';
// import useToggle from '../components/hooks/useToggle';
import WorkArea from '../components/WorkArea';
import ModalError from 'src/components/error';

function App() {
  // const { onToggle, toggle } = useToggle(false);
  return (
    <>
      <Header></Header>
      {/* <MainMenu isOpen={toggle} onToggle={onToggle}></MainMenu> */}
      <WorkArea />
      <ModalError />
    </>
  );
}

export default App;
