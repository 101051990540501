import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './order.module.scss';
import QuestionControl from 'src/components/questions/questionControl';

export default function Question({
  description,
  type,
  min,
  max,
  currentUnit,
  value,
  availableValues,
  units,
  readOnly,
  colorCode
}) {
  return (
    <div className={styles.question}>
      <Typography variant="h6" pb={2}>
        {description}
      </Typography>
      <QuestionControl
        type={type}
        value={value}
        min={min}
        max={max}
        currentUnit={currentUnit}
        availableValues={availableValues}
        units={units}
        readOnly={readOnly}
        colorCode={colorCode}
      />
    </div>
  );
}
