import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './home.module.scss';
import { Paper, Typography } from '@mui/material';

export default function Space({ name, description = 'Описание программы', id }) {

  const history = useNavigate();
  
  return (
    <Paper className={styles.space} elevation={2} onClick={() => history(`/questions/${id}`)}>
      <Typography variant="h5">{name}</Typography>
      <Typography variant="body1">{description}</Typography>
    </Paper>
  );
}