/** @format */

const transformClassObjectToClassNames = (classObject, styleModule) =>
  Object.entries({ ...classObject })
    .filter(kv => !!kv[1])
    .map(kv => (styleModule ? styleModule[kv[0]] : kv[0]));

const getClassNames = (classExpr, styleModule) =>
  Array.isArray(classExpr)
    ? classExpr.flatMap(c => getClassNames(c, styleModule))
    : typeof classExpr === 'object'
    ? transformClassObjectToClassNames(classExpr, styleModule)
    : typeof classExpr === 'string'
    ? [styleModule ? styleModule[classExpr] : classExpr]
    : [];

/**
 * Builds class name from class expression
 * @param {string | Object<string, boolean> | Array<string | Object<string, boolean>>} plainClassExpr Class expression
 * @param {string | Object<string, boolean> | Array<string | Object<string, boolean>>} moduleClassExpr Module class expression
 * @param {Object<string, string>} styleModule CSS module
 * @returns {string}
 */
const getClassName = (plainClassExpr, moduleClassExpr, styleModule) =>
  [
    ...getClassNames(plainClassExpr),
    ...getClassNames(moduleClassExpr, styleModule),
  ].join(' ');

/**
 * Creates CSS module class name builder
 * @param Object styleModule
 * @returns {getClassName~inner}
 */
const cssModuleClassNameGetter =
  styleModule => (plainClassExpr, moduleClassExpr) =>
    getClassName(plainClassExpr, moduleClassExpr, styleModule);

export { getClassName, cssModuleClassNameGetter };
