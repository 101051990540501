import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import styles from './res.module.scss';
import { Button } from '@mui/material';


export default function OrderRequest() {
  const history = useNavigate();
  const [inputCode, setCode] = useState('');

  const requestAnalysis = () => {
    history(`/order/${inputCode}`);
  };

  return (
    <Paper className={styles.container}>
      <Typography variant="h5" gutterBottom>
        Просмотр результата запроса
      </Typography>
      <div className={styles.result}>
        <TextField
          label="Код запроса"
          value={inputCode}
          onChange={(e) => {
            setCode(e.target.value);
          }}
          helperText=""
        ></TextField>
      </div>
      <div className={styles.management}>
        <Button
          variant="contained"
          disabled={!inputCode}
          onClick={requestAnalysis}
        >
          Запросить анализ
        </Button>
      </div>
    </Paper>
  );
}
