import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(state, action) {
      state.data = action.payload;
    },
    clearError(state, action) {
      state.data = null;
    },
  },
});

export const { setError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
