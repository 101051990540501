import React from 'react';
import { Typography, Divider } from '@mui/material';
import { Box } from '@mui/system';

export default function ConditionalData({ data }) {
  if (!data || !data.length) {
    return null;
  }

  return (
    <>
      <Divider variant="fullWidth" />
      {data.map((res) => {
        const { result, questions } = res || {};
        const { description } = result;

        if (!questions || !questions.length || !result) {
          return null;
        }

        return (
          <Box pl={2}>
            <Typography variant="body1">
              Для срабатывания алгоритма с результатом "{description}" необходимо
              предоставить информацию о:
            </Typography>
            {questions.map((q) => {
              const { description: questionDescription } = q;
              return (
                <Typography variant="body1" pl={2}>
                  - {questionDescription}
                </Typography>
              );
            })}
          </Box>
          
        
        );
      })}
      <Divider variant="fullWidth" />
    </>
  )
}
