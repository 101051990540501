import React from 'react';
import { Alert, Link, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {ReactComponent as TelegramLogo} from 'src/assets/img/TelegramLogo.svg';

export default function About() {
  return (
    <Paper>
      <Typography variant="h4" pl={4} pr={4} pt={4} pb={2}>
        Ламетри
        <Link href="https://t.me/lamettrie" underline="none">
          <TelegramLogo style={{width:'24px', height:'24px', marginLeft: '24px'}}></TelegramLogo>
        </Link>
      </Typography>
      <Typography variant="h6" pl={4} pr={4}>
        Cистема поддержки принятия решений в области медицины на основе технологического ядра Радиант-С.
      </Typography>
      <Typography variant="body1" pl={4} pr={4} pt={1}>
        Ламетри представляет собой базу оцифрованных клинических рекомендаций по различным заболеваниям и/или состояниям.
        <Typography variant="body1" pt={1} pb={4}>
          Задачи системы:
          <Typography variant="body1" pt={1} pl={1}>
            <b>Для пациентов:</b> анализ медицинской информации с предоставлением выводов на основании утвержденных медицинских стандартов и клинических рекомендаций.
          </Typography>
          <Typography variant="body1" pt={1} pl={1}>
            <b>Для врачей:</b> эффективная обоснованная поддержка принятия решений
          </Typography>
          <Typography variant="body1" pt={1} pl={1}>
            <b>Для организаций:</b> автоматизированный сбор, категоризация и анализ медицинских данных для выявления пациентов определенных групп по заданным параметрам, в том числе после алгоритмической обработки любой комбинаций входящих данных (лабораторные, анамнестические, демографические и пр.).
          </Typography>
        </Typography>
        <Alert severity="info">
          Предупреждение: система не ставит диагнозы и не назначает лечение. Все результаты работы  системы соответствуют регламентирующим документам, находящимся в открытом доступе.
        </Alert>
        <Typography variant="body1" pt={2}>
          Наборы алгоритмов Системы доступны в разделе{' '}
          <Link href="#" underline="none">
            программы
          </Link>
          .
        </Typography>
      </Typography>
      <Box p={3} pb={1}>
        <Typography pl={1} variant="h6">Краткая инструкция пользователя</Typography>
        <Typography variant="body1" pt={1} pl={2}>
          1.	Зайдите в раздел “Программы” и выберите интересующий вас раздел<br/>
          2.	Внесите имеющиеся у вас данные. Не забывайте указывать единицы измерения для цифровых данных<br/>
          3.	Поля, для которых у вас нет данных, оставьте пустыми<br/>
          4.	Нажмите кнопку “Анализ”<br/>
          5.	Откроется окно  результата, а также в верхней части страницы будет код вашего запроса<br/>
          6.	Код запроса можно сохранить и использовать для повторного внесения и/или коррекции данных в любой из программ<br/>
          7.	Результат работы алгоритма возможен в двух вариантах:
          <Typography variant="body1" pl={2}>
            7.1 Алгоритм сработал. Вы увидите результат и ссылки на документы-источники<br/>
            7.2 Алгоритм не сработал полностью из-за недостатка информации. В этом случае вы увидите список данных, которые необходимы алгоритму. <br/>
          </Typography>
          8.	Цвет значения отражает его оценку в системе<br/>
          9.	Если при внесении данных вы не можете найти нужную единицу измерения для определенного параметра, напишите об этом в нашем канале в телеграме<br/>
          10.	Полная инструкция а также обратная связь доступна в{' '}
          <Link href="https://t.me/lamettrie" underline="none">
            телеграм
          </Link>
        </Typography>     
      </Box>
      <Box p={3}>
        <Typography pl={1} variant="h6">Информация о технологии ядра</Typography>
        <Typography variant="body1" pt={1} pl={1}>
          Радиант-С (Радиант Семантический) это технологическое ядро позволяющее строить продукционные модели знания на основе семантической сети в виде ориентированного графа.
        </Typography>
        <Typography variant="body1" pt={1} pl={1}>
          Радиант-С позволяет:
        </Typography>
        <Typography variant="body1" pt={1} pl={2} pb={1}>
          1.	получать любые цифровые и текстовые данные (в виде анкет, опросников и т.д.) и автоматически преобразовывать их в семантический вид.<br/>
          2.	оценивать данные при преобразовании для приведения их к универсальному виду. <br/>
          3.	запускать алгоритмы в виде явного срабатывания и в виде вероятностного срабатывания, необходимого для оценки неполной информации. <br/>
          4.	разделять личные и семантически преобразованные данные для соответствия регламенту по защите персональных данных (GDPR).<br/>
          5.	анализировать как исходные, так и преобразованные данные для увеличения точности алгоритмов с использованием машинного обучения.
        </Typography>  
        <Typography variant="body1" pt={1} pl={1} pb={2}>
          Разработкой системы руководит Константин Карузин, автор более 20 публикаций и один из создателей и бывший медицинский директор компании bioniq. В приведенной публикации показаны возможности использования цифровых алгоритмов для назначений микронутриентов. <Link href="http://journal-archiveuromedica.eu/archiv-euromedica-03-2020/archiv_euromedica_03_2020_maket_001_141_READY_FOR_WEB_splited_NEW_article_15.pdf" underline="none" target={'_blank'}>
            Ссылка на публикацию
          </Link>
        </Typography>
      </Box>
    </Paper>
  );
}
