import React, { useEffect, useRef } from 'react';
import useApi from 'src/Api/useApi';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, Paper, Typography, Skeleton } from '@mui/material';
import Question from 'src/components/order/question';
import PendingRows from 'src/components/ui/pendingRows';
import { Box, IconButton, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import styles from './order.module.scss';
import OrderData from './orderData';
import ConditionalData from './conditionalData';

export default function Order() {
  const history = useNavigate();
  const params = useParams();
  const { code } = params || {};
  const { api, inProgress } = useApi();
  const [orderData, setOrderData] = useState([]);
  const [conditionalOrderData, setConditionalOrderData] = useState([]);
  const [spaceId, setSpaceId] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  // const [units, setUnits] = useState([]);
  const cancel = useRef(null);

  useEffect(() => {
    const local = async () => {
      const orderData = await api('analysis/order', code, {
        setCancelCallback: (callback) => (cancel.current = callback),
      });

      // const units = await api('analysis/units');
      const { isError } = orderData;
      if (isError) {
        return;
      }
      const { notConditional, conditional } = orderData;

      setOrderData(notConditional);
      setConditionalOrderData(conditional);
      // setUnits(units);
      orderData?.spaceId && setSpaceId(orderData?.spaceId);
    };
    local();

    return () => cancel?.current && cancel.current.abort();
  }, []);

  const handleCopyClick = () => {
    setSnackOpen(true);
    navigator.clipboard.writeText(code);
  };

  return (
    <>
      <Snackbar
        message="Номер скопирован"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={1500}
        onClose={() => setSnackOpen(false)}
        open={snackOpen}
      />
      <Paper className={styles.paper}>
        <Typography variant="h5" p={3}>
          {`Результат обработки запроса  ${code || ''}  `}
          <IconButton
            onClick={handleCopyClick}
            color="primary"
            disabled={!code}
          >
            <ContentCopyIcon />
          </IconButton>
        </Typography>
        {inProgress && (
          <Typography variant="h3" pb={2} pl={3} pr={3}>
            <Skeleton />
          </Typography>
        )}
        <Divider variant="fullWidth" />
        <div className={styles.container}>
          {!inProgress &&
            orderData
              .filter((i) => i.type === 0)
              .map((i) => (
                <Typography variant="body1" pb={1} pl={1}>
                  {i.description}
                </Typography>
              ))}
          <Divider variant="fullWidth" />
          <Typography variant="h5" p={1}>
            Анализируемые данные:
          </Typography>
          <Divider variant="fullWidth" />
          <PendingRows inProgress={inProgress} />
          {!orderData.length && !inProgress && !orderData.length && (
            <Typography style={{ textAlign: 'center' }} variant="h5">
              Нет данных для данного запроса
            </Typography>
          )}
          <OrderData inProgress={inProgress} orderData={orderData} />
          <ConditionalData inProgress={inProgress} data={conditionalOrderData} />
        </div>
      </Paper>
    </>
  );
}
