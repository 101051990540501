// import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import api from './service'
import { setError } from 'src/redux/error';
import { useState } from 'react';

const useApi = () => {

  const [ inProgress, setInProgress ] = useState(false);
  const dispatch = useDispatch()

  const apiCall = async (...args) => {
    let result;
    
    setInProgress(true)
    try {
      result = await api(...args)
      const { isError, errors } = result
      isError && errors && errors[0] && dispatch(setError(errors[0]))
    } catch (error) {
      dispatch(setError(error))
    } finally {
      setInProgress(false)
    }

    return result
  }

  return {
    api: apiCall,
    inProgress
  }
}

export default useApi