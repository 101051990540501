import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { NavLink } from 'react-router-dom';
import { AppBar, Box } from '@mui/material';

export default function Header({}) {
  return (
    <AppBar component="nav">
      <Box sx={{ display: 'flex', paddingLeft: '20px' }}>
        <List sx={{ display: 'flex' }}>
          <ListItem key={'About'} disablePadding onClick={null}>
            <ListItemButton
              color="inherit"
              component={NavLink}
              key={'About'}
              onClick={null}
              to="/About"
            >
              <ListItemText primary="О системе" sx={{ whiteSpace: 'nowrap' }}/>
            </ListItemButton>
          </ListItem>
          <ListItem key={'Home'} disablePadding onClick={null}>
            <ListItemButton
              color="inherit"
              component={NavLink}
              key={'Home'}
              onClick={null}
              to="/"
            >
              <ListItemText primary="Программы" />
            </ListItemButton>
          </ListItem>
          <ListItem key={'order'} disablePadding onClick={null}>
            <ListItemButton to="/orderRequest" component={NavLink}>
              <ListItemText primary="Результат" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </AppBar>
  );
}
