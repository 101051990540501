import React, { useState } from 'react';
import { getClassName } from 'src/Core/utility/jsx';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Number from 'src/components/ui/input/number';
import styles from './questions.module.scss';
import Typography from '@mui/material/Typography';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function QuestionControls({
  uuid,
  type,
  value,
  availableValues,
  setValue,
  min,
  setMin,
  setMax,
  max,
  currentUnit,
  setCurrentUnit,
  units,
  readOnly,
  colorCode,
}) {
  const [firstValue, setAutocompleteInput] = useState('');

  return (
    <div className={getClassName(styles.controlsContainer)}>
      {type === 1 && (
        <>
          <Number
            label="Значение"
            value={value || ''}
            onChange={setValue}
            readOnly={readOnly}
            color={getColor(colorCode)}
          />
          <Autocomplete
            readOnly={readOnly}
            value={currentUnit || null}
            onChange={(event, newValue) => {
              setCurrentUnit(newValue);
            }}
            inputValue={firstValue}
            onInputChange={(event, newInputValue) => {
              setAutocompleteInput(newInputValue);
            }}
            options={units}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Ед.изм" />}
          />
        </>
      )}
      {type === 2 && (
        <>
          <Number
            label="Значение"
            value={value || ''}
            onChange={setValue}
            readOnly={readOnly}
            color={getColor(colorCode)}
          />
          <Number
            readOnly={readOnly}
            label="Min"
            value={min || ''}
            onChange={setMin}
          />
          <Number
            readOnly={readOnly}
            label="Max"
            value={max || ''}
            onChange={setMax}
          />
          <Autocomplete
            readOnly={readOnly}
            value={currentUnit || null}
            onChange={(event, newValue) => {
              setCurrentUnit(newValue);
            }}
            inputValue={firstValue}
            onInputChange={(event, newInputValue) => {
              setAutocompleteInput(newInputValue);
            }}
            options={units}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Ед.изм" />}
          />
        </>
      )}
      {type === 3 && (
        <Autocomplete
          readOnly={readOnly}
          value={value || null}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          inputValue={firstValue}
          onInputChange={(event, newInputValue) => {
            setAutocompleteInput(newInputValue);
          }}
          options={availableValues}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField {...params} label="Возможные варианты" />
          )}
        />
      )}
      {type === 4 && (
        <Autocomplete
          multiple
          readOnly={readOnly}
          disableCloseOnSelect
          options={availableValues}
          value={value || []}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          getOptionLabel={(option) => option.title}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Возможные варианты" />
          )}
        />
      )}
    </div>
  );
}

const getColor = (id) => {
  switch (id) {
    case 0:
      return 'mediumpurple';
    case 1:
      return 'dodgerblue';
    case 2:
      return 'lightskyblue';
    case 3:
      return 'lightgreen';
    case 4:
      return 'lightyellow';
    case 5:
      return 'lightsalmon';
    case 6:
      return 'orangered';
    default:
      return 'transparent';
  }
};
