import React from 'react';
import { TextField } from '@mui/material';
import styles from './number.module.scss';

const isValidValue = value => {
  const regexp = new RegExp(/^((\d+(\.\d*)?)|(\.\d+))$/)
  return !value || regexp.test(value)
}

export default function Number({
  onChange,
  value,
  label,
  placeholder,
  error,
  readOnly,
  color,
}) {
  return (
    <TextField
      className={styles.field}
      readOnly={readOnly}
      error={error}
      value={value}
      label={label}
      placeholder={placeholder}
      onChange={(e) => {
        isValidValue(e.target.value) && onChange(e.target.value);
      }}
      style={{ backgroundColor: color }}
    ></TextField>
  );
}
