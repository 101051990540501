import { Skeleton, Typography } from '@mui/material'
import React from 'react'

export default function PendingRows({ count = 5, variant = 'h3', inProgress = false }) {

  return (
    <>
      {inProgress &&
        Array(count)
          .fill(null)
          .map((e, i) => (
            <Typography variant={variant} key={i}>
              <Skeleton />
            </Typography>
          ))}
    </>
  )
}
