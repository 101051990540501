import React from 'react';
import Question from 'src/components/order/question';

export default function OrderData({ orderData, inProgress }) {
  if (inProgress || !orderData || !orderData.length) {
    return null
  }

  return (
    orderData
      .filter((i) => i.type !== 0)
      .map((i) => (
        <Question
          readOnly
          {...i}
          key={i.uuid}
          availableValues={getАvailableValues(i)}
          units={[]}
          currentUnit={{ name: i.unitData?.description }}
          value={getValue(i)}
        />
      ))
  );
}

const getАvailableValues = (answer) => {
  return answer.answerSEValue
    ? [
        {
          title: answer.answerSEValue.description,
          value: answer.answerSEValue.seValue,
        },
      ]
    : answer.answerSEValues
    ? answer.answerSEValues.map((v) => ({
        title: v.description,
        value: v.seValue,
      }))
    : [];
};

const getValue = (answer) => {
  switch (Number(answer.type)) {
    case 1:
    case 2:
      return answer.value;
    case 3:
      return { title: answer.answerSEValue?.description };
    case 4:
      return answer.answerSEValues?.map((i) => ({
        title: i.description,
      }));
  }
};