import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { clearError } from 'src/redux/error';
import { useDispatch } from 'react-redux';

export default function ModalError() {
  const { data } = useSelector((state) => state.error);
  const dispatch = useDispatch();

  return (
    <Dialog
      open={!!data}
      scroll={'paper'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Ошибка</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ minWidth: '40vh', minHeight: '20vh' }}
          id="alert-dialog-description"
        >
          {data?.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(clearError())}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  );
}
